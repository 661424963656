@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.reassurance-payment {
	display: flex;
	gap: 8px;
	flex-direction: column;
	align-items: center;

	&__card-payment {
		margin-top: 10px;
		display: flex;
		gap: 8px;
		align-items: center;
	}

	&__mobile-payment {
		display: flex;
		gap: 8px;
	}

	@include gridle_state(md) {
		text-align: left;
		align-items: baseline;
	}
}
